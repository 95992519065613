<template>
  <v-card class="my-5">
    <v-toolbar color="green darken-2" dark>
      <v-toolbar-title class="row justify-center">Новости</v-toolbar-title>
    </v-toolbar>
    <div class="pa-5">
      <div class="row no-gutters justify-space-between">
        <v-btn color="primary" to="/news/create">Создать новость</v-btn>
        <h2 :class="$vuetify.breakpoint.xs ? 'pt-2' : ''">Количество новостей: {{ count }}</h2>
      </div>
      <div class="pt-5" v-for="(item, index) in news" :key="item.id">
        <v-card outlined>
          <div class="row no-gutters justify-space-between align-center">
            <v-card-title class="pb-1">{{ item.title }}</v-card-title>
            <span class="pr-6 pt-2">{{ item.created_at | timeFormat }}</span>
          </div>
          <div class="row no-gutters px-3">
            <v-col cols="12" class="col col-lg-3 col-md-3 col-sm-3">
              <v-img @click="$refs.image[index].click()" v-if="item.image" :src="item.image" alt="image" width="265px" height="300px" />
              <v-img @click="$refs.image[index].click()" v-else src="/1.png" alt="image" width="265px" height="300px" />
            </v-col>
            <div v-show=false>
              <input id="image"
                     ref="image"
                     accept="image/*"
                     type="file"
                     @change="handleImageUpload(index, item)"/>
            </div>
            <v-col cols="12" class="col col-lg-9 col-md-9 col-sm-9" :class="$vuetify.breakpoint.xs ? 'pt-2' : ''">
              <v-img v-if="item.thumbnail" :src="item.thumbnail" height="300px"></v-img>
              <v-img v-else src="/1.png" height="300px"></v-img>
            </v-col>
          </div>
          <v-card-text class="pb-0" v-html="item.description"></v-card-text>
          <v-card-actions class="pl-5 pb-4">
            <v-btn color="blue" dark @click="edit(item)">Редактировать</v-btn>
            <v-btn color="error" dark @click="deleteOne(item.id)">Удалить</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </v-card>
</template>

<script>
import format from "date-fns/format";

export default {
  mounted() {
    this.$store.dispatch("news/fetch");
  },
  computed: {
    news() {
      return this.$store.getters["news/news"];
    },
    count() {
      return this.$store.getters["news/count"];
    },
    error() {
      return this.$store.getters["error"];
    }
  },
  methods: {
    edit(item) {
      this.$router.push({name: 'newsEdit', params: {id: item.id} })
    },
    handleImageUpload(index, item) {
      let file = this.$refs.image[index].files[0];
      if (file) {
        this.$store.dispatch("news/uploadImage", {id: item.id, file: file});
      }
    },
    deleteOne(id){
      this.$confirm(
          "Удалить ?",
          "Внимание",
          "question"
      ).then(() => {
        this.$store.dispatch("news/deleteOne", id);
      })
    }
  },
  filters: {
    timeFormat(d) {
      if (!d) {
        return ""
      }
      return format(new Date(d), "yyyy-MM-dd")
    }
  }
}
</script>
